// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-blog-post-layout-tsx": () => import("./../../../src/components/Blog/BlogPostLayout.tsx" /* webpackChunkName: "component---src-components-blog-blog-post-layout-tsx" */),
  "component---src-components-portfolio-portfolio-post-layout-tsx": () => import("./../../../src/components/Portfolio/PortfolioPostLayout.tsx" /* webpackChunkName: "component---src-components-portfolio-portfolio-post-layout-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-uses-tsx": () => import("./../../../src/pages/uses.tsx" /* webpackChunkName: "component---src-pages-uses-tsx" */),
  "component---src-pages-work-design-tsx": () => import("./../../../src/pages/work/design.tsx" /* webpackChunkName: "component---src-pages-work-design-tsx" */),
  "component---src-pages-work-dev-tsx": () => import("./../../../src/pages/work/dev.tsx" /* webpackChunkName: "component---src-pages-work-dev-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */)
}

