import HomepointLogoBlock from "../../../../src/components/Homepoint/HomepointLogoBlock";
import HomepointColors from "../../../../src/components/Homepoint/HomepointColors";
import HomepointBrandDeck from "../../../../src/components/Homepoint/HomepointBrandDeck";
import { InlineButton, ButtonContainer } from "../../../../src/components/SimpleButton";
import * as React from 'react';
export default {
  HomepointLogoBlock,
  HomepointColors,
  HomepointBrandDeck,
  InlineButton,
  ButtonContainer,
  React
};