import { HomepointIdentityColors, HomepointSupportColors, HomepointSupportTints } from "../../../../src/components/Homepoint/HomepointColors";
import { SingleComboExample, DualComboExample, DefaultTextSamples, LegibleCombinations } from "../../../../src/components/Homepoint/ColorExamples";
import * as React from 'react';
export default {
  HomepointIdentityColors,
  HomepointSupportColors,
  HomepointSupportTints,
  SingleComboExample,
  DualComboExample,
  DefaultTextSamples,
  LegibleCombinations,
  React
};